import { LottieAnimationData } from './LottieAnimationData';

/**
 * Helper function to eliminate the need to manually type the path and fallback
 * while also retaining the string type.
 */
function lottieAnimationData<
  TPath extends string,
  TFallbackPath extends string | undefined,
>(data: LottieAnimationData<TPath, TFallbackPath>) {
  return data;
}

export const expiredListingLottieAnimationData = lottieAnimationData({
  path: 'animations/expired-listing-animation.json',
  fallbackPath: 'illustrations/expired-listing-illustration.svg',
  width: 586,
  height: 434,
});

export const lockAnimation = lottieAnimationData({
  path: 'animations/lock.json',
  color: 'white',
  fallbackPath: 'icons/normalized/lock-filled.svg',
  width: 24,
  height: 24,
});

export const unlockAnimation = lottieAnimationData({
  path: 'animations/lock.json',
  color: 'white',
  fallbackPath: 'icons/normalized/lock-open-filled.svg',
  width: 24,
  height: 24,
});

export const likeAnimation = lottieAnimationData({
  path: 'animations/like.json',
  fallbackPath: 'icons/animated/like.svg',
  width: 24,
  height: 24,
});

export const loveAnimation = lottieAnimationData({
  path: 'animations/love.json',
  fallbackPath: 'icons/animated/love.svg',
  width: 24,
  height: 24,
});

export const inspiredAnimation = lottieAnimationData({
  path: 'animations/inspired.json',
  fallbackPath: 'icons/animated/inspired.svg',
  width: 24,
  height: 24,
});
