import styled from 'styled-components';

import {
  bodyText,
  cssBreakpoints,
  gutterWidth,
  h2Text,
  maxWidth,
} from 'theme/theme';

export const CareersNotFoundContentHolder = styled.div`
  margin: 70px auto;
  max-width: ${maxWidth[4]}px;
  padding: 0 ${gutterWidth / 2}px;
`;

export const CareersNotFoundContentUpperSection = styled.section`
  display: flex;
  gap: 83px;
  margin-bottom: 70px;
  align-items: center;

  @media screen and (max-width: ${cssBreakpoints.mdDown}) {
    flex-direction: column;
    align-items: stretch;
    gap: 32px;
  }
`;

export const CareersNotFoundContentUpperSectionAnimation = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding-bottom: 32px;
`;

export const CareersNotFoundContentUpperSectionAnimationHolder = styled.div`
  width: 100%;
  max-width: 586px;
`;

export const CareersNotFoundContentUpperSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CareersNotFoundContentUpperSectionHeader = styled.header({});

export const CareersNotFoundContentUpperSectionHeaderTitle = styled.h1`
  ${h2Text};

  margin: 0;
  max-width: 416px;
`;

export const CareersNotFoundContentUpperSectionSeeMore = styled.div`
  ${bodyText};
  max-width: 416px;
`;

export const CareersNotFoundContentUpperSectionFooter = styled.footer`
  margin-top: 43px;
  max-width: 360px;
`;

export const CareersNotFoundContentUpperSectionFooterSearchField = styled.div`
  display: flex;
  gap: 16px;
`;
