import { useEffect, useState } from 'react';

import { loadLottieOnce } from './loadLottieOnce';

export function useLottiePlayer() {
  const [lottieLoaded, setLottieLoaded] = useState(
    Boolean(global.window && window.lottie),
  );

  useEffect(() => {
    if (lottieLoaded) return;
    loadLottieOnce().then(() => setLottieLoaded(true));
  }, [lottieLoaded]);

  const lottiePlayer =
    global.window && window.lottie ? window.lottie : undefined;

  return { lottiePlayer };
}
