import interpolateComponents from '@automattic/interpolate-components';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { Button } from 'components/Button/Button';
import { Form } from 'components/Form/Form';
import { InputField } from 'components/Form/InputField';
import { Icon } from 'components/Icon/Icon';
import { StyledVersionedRouterLink } from 'components/Link/StyledVersionedRouterLink';
import { LottieAnimation } from 'components/LottieAnimation/LottieAnimation';
import { expiredListingLottieAnimationData } from 'components/LottieAnimation/lottieAnimationsData';
import { H3 } from 'components/Text/H3';
import { HeadingSmall } from 'components/Text/HeadingSmall';
import { VisuallyHidden } from 'components/VisuallyHidden/VisuallyHidden';
import { getParsedSearchQuery } from 'modules/search/helpers/getParsedSearchQuery';
import { SearchPageLocationState } from 'modules/search/pages/Search/types/SearchPageLocationState';
import { searchGetJobsRoutePath } from 'modules/search/routing/helpers/searchGetJobsRoutePath';
import { trackClicked } from 'utils/analytics/track/trackClicked';

import {
  CareersNotFoundContentHolder,
  CareersNotFoundContentUpperSection,
  CareersNotFoundContentUpperSectionAnimation,
  CareersNotFoundContentUpperSectionAnimationHolder,
  CareersNotFoundContentUpperSectionContent,
  CareersNotFoundContentUpperSectionFooter,
  CareersNotFoundContentUpperSectionFooterSearchField,
  CareersNotFoundContentUpperSectionHeader,
  CareersNotFoundContentUpperSectionHeaderTitle,
  CareersNotFoundContentUpperSectionSeeMore,
} from './CareersNotFoundContent.styled';

type CareersNotFoundContentSearchValues = { query: string };

export function CareersNotFoundContent() {
  const navigate = useNavigate();
  const search = useCallback(
    (query: string) => {
      const { parsedQuery, locationType, remoteKeywords } =
        getParsedSearchQuery({ query });

      navigate(
        searchGetJobsRoutePath({
          lang: CURRENT_LOCALE(),
          q: parsedQuery,
          filtersByName: { locationType },
        }),
        {
          state: {
            searchFrom: 'Careers Content Not Found Page',
            remoteKeywords,
          } satisfies SearchPageLocationState,
        },
      );
    },
    [navigate],
  );
  return (
    <CareersNotFoundContentHolder>
      <CareersNotFoundContentUpperSection>
        <CareersNotFoundContentUpperSectionAnimation>
          <CareersNotFoundContentUpperSectionAnimationHolder>
            <LottieAnimation
              data={expiredListingLottieAnimationData}
              size={undefined}
            />
          </CareersNotFoundContentUpperSectionAnimationHolder>
        </CareersNotFoundContentUpperSectionAnimation>

        <CareersNotFoundContentUpperSectionContent>
          <CareersNotFoundContentUpperSectionHeader>
            <HeadingSmall>{getText('Unpublished page')}</HeadingSmall>

            <CareersNotFoundContentUpperSectionHeaderTitle>
              {getText(
                'Whoops! It looks like this post is no longer available.',
              )}
            </CareersNotFoundContentUpperSectionHeaderTitle>
          </CareersNotFoundContentUpperSectionHeader>

          <CareersNotFoundContentUpperSectionSeeMore>
            {interpolateComponents({
              mixedString: getText(
                "Don't worry, we have plenty {{link}}more Career Advice{{/link}} " +
                  'posts to help you throughout your social-impact career!',
              ),
              components: {
                link: (
                  <StyledVersionedRouterLink
                    to="/en/careers?pq="
                    onClick={() => {
                      trackClicked('Career Advice 404 Page Link');
                    }}
                  />
                ),
              },
            })}
          </CareersNotFoundContentUpperSectionSeeMore>

          <CareersNotFoundContentUpperSectionFooter>
            <H3>{getText('Search Jobs')}</H3>

            <Form
              analyticsTitle="[main] Careers Content Not Found - search"
              usageContext="page"
              onSubmit={({ values }) => search(values.query)}
              initialValues={
                { query: '' } satisfies CareersNotFoundContentSearchValues
              }
            >
              <CareersNotFoundContentUpperSectionFooterSearchField>
                <InputField
                  id="unpublished-listing-query"
                  placeholder={getText('Search by keyword, skill, or interest')}
                  name="query"
                  noMargin
                />

                <Button type="submit">
                  <Icon name="search" size={16} />
                  <VisuallyHidden>{getText('Search')}</VisuallyHidden>
                </Button>
              </CareersNotFoundContentUpperSectionFooterSearchField>
            </Form>
          </CareersNotFoundContentUpperSectionFooter>
        </CareersNotFoundContentUpperSectionContent>
      </CareersNotFoundContentUpperSection>
    </CareersNotFoundContentHolder>
  );
}
