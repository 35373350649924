import { useLocation } from 'react-router';

import { Box } from 'components/Box/Box';
import { CareersNotFoundContent } from 'components/CareersNotFoundContent/CareersNotFoundContent';
import { NotFoundContent } from 'components/NotFoundContent/NotFoundContent';
import { useSetDocumentTitle } from 'hooks/useSetDocumentTitle';
import { useTrackPageView } from 'hooks/useTrackPageView';
import { Layout } from 'layouts/Layout/Layout';
import { gutterWidth, maxWidth } from 'theme/theme';

type Props = {
  searchText?: string;
  searchUrl?: string;
  noLayout?: boolean;
};

export function NotFoundPage({ searchText, searchUrl, noLayout }: Props) {
  const location = useLocation();
  const isCareers = location.pathname.startsWith('/en/careers/');
  /* TRANSLATORS: 404 page title */
  useSetDocumentTitle(getText('Page Not Found'));

  useTrackPageView(isCareers ? 'Career Advice 404 Page' : '404 Page');

  const body = (
    <Box
      mx="auto"
      maxWidth={[480, null, maxWidth[3]]}
      px={[gutterWidth / 2, null, null, gutterWidth]}
      py={[gutterWidth / 2, null, null, gutterWidth, '28px']}
    >
      {isCareers ? (
        <CareersNotFoundContent />
      ) : (
        <NotFoundContent searchText={searchText} searchUrl={searchUrl} />
      )}
    </Box>
  );

  if (noLayout) return body;

  return <Layout>{body}</Layout>;
}
